/*--------------------------------------------------------------
## Basic Color
----------------------------------------------------------------*/
$white: #fff;
$black: #080810;
$primary: #FEFEFE;
$secondary: rgba($primary, 0.7);
$ternary: #999696;
$border: #4D4D4D;
$gray: #080810;
$accent: rgb(255, 30, 0);
