::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-track {
  background: rgb(255, 30, 0);
  border-radius: 4px;
}

::-webkit-scrollbar-thumb {
  background: #333;
  border-radius: 4px;
}

.Loading {
    background: radial-gradient(#080810 20%, #000001);
    justify-content: center;
    align-items: center;
    position: absolute;
    height: 100vh;
    width: 100vw;
    display:flex;
    z-index:999;
}

/* Loading particles */
.stage {
    transform: translate(-50%, -50%);
    background: transparent;
    position: absolute;
    overflow: hidden;
    height: 100vh;
    width: 100vw;
    z-index: 1;
    left: 50%;
    top: 50%;
}

.stage .ping {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 0px;
    height: 0px;
    box-shadow: inset 0 0 200px rgba(26, 159, 183, 0);
    border: 1px solid rgba(26, 159, 183, 0);
    border-radius: 50%;
    animation: ping 3s ease infinite;
    z-index: 2;
}
  
@keyframes ping {
    0% {
      width: 0px;
      height: 0px;
      box-shadow: inset 0 0 200px rgba(255, 128, 0, 0.2);
      border: 1px solid rgba(255, 162, 0, 0.8);
    }
    60% {
      box-shadow: inset 0 0 200px rgba(0, 217, 255, 0);
      border: 1px solid rgba(255, 128, 0, 0.1);
    }
    90% {
      width: 90vmin;
      height: 90vmin;
    }
}